body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor {
  animation: cursorBlinkAnimation 1s infinite;
}

table, th, td {
  border: 3px solid black;
  border-collapse: collapse;
  padding: 14px;
  font-size: 20px;
}
.exact-center {
  width:200px;
  height:200px;
  position: fixed;
  background-color: #ff92ed;
  top: 35%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
}
@keyframes cursorBlinkAnimation {
  0% {
    background-color: #cce5ff; /* light blue */
  }
  50% {
    background-color: #99c2ff; /* medium blue */
  }
  100% {
    background-color: #cce5ff; /* back to light blue */
  }
  
}

.bad-answer {
  animation: badAnswerBlinkAnimation 1s infinite;
}

@keyframes badAnswerBlinkAnimation {
  0% {
    background-color: #ffcccc; /* light blue */
  }
  50% {
    background-color: #f96161; /* medium blue */
  }
  100% {
    background-color: #ffcccc; /* back to light blue */
  }
  
}

.ok-answer {
  animation: okAnswerBlinkAnimation 1s infinite;
}

@keyframes okAnswerBlinkAnimation {
  0% {
    background-color: #ffefcc; /* light blue */
  }
  50% {
    background-color: #f9c461; /* medium blue */
  }
  100% {
    background-color: #ffefcc; /* back to light blue */
  }
  
}

.good-answer {
  animation: goodAnswerBlinkAnimation 1s infinite;
}

@keyframes goodAnswerBlinkAnimation {
  0% {
    background-color: #d1ffcc; /* light blue */
  }
  50% {
    background-color: #61f97a; /* medium blue */
  }
  100% {
    background-color: #d1ffcc; /* back to light blue */
  }
  
}
#labyrinth{
  margin: auto;
  display: table;
  overscroll-behavior: none;
}

html, body{
  overscroll-behavior: none;
}